import React from 'react';
import {
  InstantSearch,
  Hits,
  Highlight,
  SearchBox,
  Pagination,
  ClearRefinements,
  RefinementList,
  Configure,
} from 'react-instantsearch-dom';
import PropTypes from 'prop-types';
import './App.css';
import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter';

const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
  server: {
    // readonly api key for the clients
    apiKey: 'XTdgGJFI7A0NDXLsALFIOuJZHNGqg3EN',
    nodes: [
      {
        host: 'uc4ofeg3hwdtnpvqp-1.a1.typesense.net',
        port: '443',
        protocol: 'https',
      },
    ],
  },
  // The following parameters are directly passed to Typesense's search API endpoint.
  //  So you can pass any parameters supported by the search endpoint below.
  //  queryBy is required.
  additionalSearchParameters: {
    queryBy: 'name,description',
  },
});
const searchClient = typesenseInstantsearchAdapter.searchClient;

export function VendorSearch() {
  return (
    <div>
      <div className="ais-InstantSearch">
        <h1>Typesense demo</h1>
        <InstantSearch indexName="profiles" searchClient={searchClient}>
          <div className="left-panel">
            <ClearRefinements />
            {/* <h2>Number of Employees</h2>
            <RefinementList attribute="num_employees" /> */}
            <h2>Country</h2>
            <RefinementList attribute="country" />
            <Configure hitsPerPage={8} />
          </div>
          <div className="right-panel">
            <SearchBox />
            <Hits hitComponent={Hit} />
            <Pagination />
          </div>
        </InstantSearch>
      </div>
    </div>
  );
}

function Hit({ hit }) {
  return (
    <div>
      <img className="hit-img" src={hit.photo} align="left" alt={hit.name} />
      <div className="hit-name">
        <Highlight attribute="name" hit={hit} />
      </div>
      <div className="hit-description">
        {/* this is a bit dangerous, check out the XSS guides */}
        <div
          dangerouslySetInnerHTML={{
            __html: hit._highlightResult.description.value,
          }}
        />
        <div className="hit-price">${hit.num_employees}</div>
      </div>
    </div>
  );
}

Hit.propTypes = {
  hit: PropTypes.object.isRequired,
};
