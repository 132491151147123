import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import App from './App';

import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import { LectureSearch } from './LectureSearch';
import { VendorSearch } from './VendorSearch';
import { ProductSearch } from './ProductSearch';

// This site has 3 pages, all of which are rendered
// dynamically in the browser (not server rendered).
//
// Although the page does not ever refresh, notice how
// React Router keeps the URL up to date as you navigate
// through the site. This preserves the browser history,
// making sure things like the back button and bookmarks
// work properly.

export default function BasicExample() {
  return (
    <Router>
      <div>
        <ul>
          <li>
            <Link to="/">Lectures</Link>
          </li>
          <li>
            <Link to="/vendors">Vendors</Link>
          </li>
          <li>
            <Link to="/products">Products</Link>
          </li>
        </ul>

        <hr />

        {/*
          A <Switch> looks through all its children <Route>
          elements and renders the first one whose path
          matches the current URL. Use a <Switch> any time
          you have multiple routes, but you want only one
          of them to render at a time
        */}
        <Switch>
          <Route exact path="/">
            <LectureSearch />
          </Route>
          <Route path="/vendors">
            <VendorSearch />
          </Route>
          <Route path="/products">
            <ProductSearch />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}
ReactDOM.render(<BasicExample />, document.getElementById('root'));
